<template>
    <Dialog :title="title()" :open="open" :save="save" :saveProgress="saveProgress" @cancel="$emit('cancel')" @save="_save()">
        <v-alert
            icon="mdi-alert"
            outlined
            dense
            color="red darken-4"
        >
            {{ $t('danger_zone.warning') }}
        </v-alert>

        <div class="mt-9 text-center text-body-1 font-weight-medium black--text">
            {{ description() + " "}}

        <span v-if="descriptionContent() == $t('active')" class="text-center text-h6 green--text text--darken-2">
            {{ descriptionContent() }}
        </span>
        <span v-else class="text-center text-h6 red--text text--darken-4">
            {{ descriptionContent() }}
        </span>
        </div>

        <div class="mt-9 text-center">
            <span class="text-body-2">
                {{ $t('danger_zone.please_type') }}
            </span>
            <span class="text-body-2 font-weight-bold">
                {{ checkText() }}
            </span>
            <span class="text-body-2">
                {{ $t('danger_zone.to_confirm') }}
            </span>
        </div>

        <v-text-field
            class="mt-3"
            v-model="form.checkField"
            :label="$t('danger_zone.type_here')"
            :error-messages="errors.checkField"
            :hide-details="!errors.checkField"
            :disabled="saveProgress"
            @change="validate('checkField')"
            outlined
            dense
        />

        <v-alert
            v-if="dependenciesData"
            class="mt-6 text-body-2"
            icon="mdi-alert"
            outlined
            dense
            color="red darken-4"
        >
            <div class="mt-1 mb-2">{{ $t('danger_zone.dependencies_alert') }}</div>
            <div v-for="(value, propertyName) in dependenciesData" :key="propertyName">
                <template v-if="value > 0">
                    {{ $t('routes.' + propertyName )  + ": "}}<span class="font-weight-bold">{{value}}</span>
                </template>
            </div>
        </v-alert>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";

export default {
    components: {
        Dialog
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        item: {
            type: Object,
            default: null
        },

        save: {
            type: String,
            default: null
        },

        title: {
            type: Function,
            default: null
        },

        description: {
            type: Function,
            default: null
        },

        descriptionContent: {
            type: Function,
            default: null
        },

        checkText: {
            type: Function,
            default: null
        },

        dependenciesData: {
            type: Object,
            default: null
        },
        
        saveProgress: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        form: {
            checkField: null
        },

        errors: {
            checkField: null
        },

        show: true
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        validate() {
            Object.assign(this.$data.errors, this.$options.data().errors);

            if (this.form.checkField != this.checkText()) {
                this.errors.checkField = this.$t(`form_errors.checkField.comparision_failed`);
                return false;
            }

            return true;
        },

        async _save() {
            if (!this.validate()) return;

            this.$emit("save"); 
        }
    }
};
</script>
