<template>
    <v-card class="danger-zone-border d-flex align-start flex-column mt-3" rounded=0 flat>
            <v-card-title class="text-h5 red--text text--darken-4" style="width: 100%">
                {{ $t('danger_zone.title') }}
            </v-card-title>
            <v-card-text>
                <v-divider/>
                <div v-for="(item, index) in data" :key="index">
                    <v-row class="mt-1 mb-1" align="center" justify="space-between">
                        <v-col>
                            <p class="font-weight-medium text-body-1 text--primary ml-1 mb-1">{{ item.itemName }}</p>
                            <p class="text-caption text--secondary ml-1 mb-0">{{ item.itemInfo }}
                                <span class="font-weight-bold">{{ item.itemInfoBold }}</span>
                                <span class="green--text text--darken-3">{{ item.itemInfoGreen }}</span>
                                <span class="red--text text--darken-4">{{ item.itemInfoRed }}</span>
                            </p>
                        </v-col>
                        <v-col align="end">
                            <v-btn
                                outlined
                                color="red darken-4"
                                :disabled="disabled || item.itemDisabled"
                                :loading="item.itemProgress"
                                @click="$emit('btnClick', item)"
                            >
                                <v-icon left>mdi-alert</v-icon>{{ item.itemName }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider/>
                </div>
            </v-card-text>
        </v-card>
</template>

<style scoped>
    .danger-zone-border {
        border: #b71c1c solid 1px !important;
    }
</style>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },

        data: {
            type: Array,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    model: {
        prop: 'item',
        event: 'btnClick'
    },
};
</script>
